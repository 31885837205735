.pcommentform {
    & input[name="name"],
    & input[name="reply"]  {
        margin-right: 4px;
        height: 30px;
        vertical-align: top;
    }

    & input[type="submit"] {
        height: 30px;
        vertical-align: top;
    }

    & .auto-resize-textarea {
        resize: none;
        vertical-align: bottom;
        margin-right: 4px;
    }

    &.no-edit-assistant {
        margin-bottom: 16px;
    }

    &.has-capacity-warning {
        & input[name="name"],
        & .auto-resize-textarea {
            border: 1px solid #ff0000;
            //background-color: #ffcccc;
        }
    }

    & .edit-assistant-area {
        display: none;
        margin-bottom: 16px;

        &.shown {
            display: block;
            animation: fadeIn .8s ease;
        }

        & .comment-capacity-warning {
            color: #ff0000;
        }

        & .controls {
            display: flex;
            align-items: center;

            & .hide-edit-assistant {
                margin-left: 16px;
            }
        }
    }

    & .rotation-form {
        border: solid 1px red;
        padding: 8px;
        margin-top: 20px;

        & .rotation-form-radio {
            margin: 20px 0;
        }

        & .page-name {
            margin-left: 12px;
            margin-bottom: 12px;

            & input[type="text"] {
                margin-left: 4px;
                width: 300px;
            }
        }

        & input[type="radio"] {
            margin-right: 4px;
        }

        & .rotation-submit {
            margin-right: 16px;
        }
    }

    & .disabled {
        opacity: 0.2;
    }

    @keyframes fadeIn {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
}
