.wiki-edit-assistant {
    --assist-button-width: 40px;

    width: fit-content;
    background-color: #f5f5f5;
    padding: 6px 4px;
    margin-top: 8px;

    & ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;

        & li {
            position: relative;
        }
    }

    & .button-group {
        margin: 2px 4px;

        & li:first-child button.assist-button {
            border-left: 1px solid #aaa;
            border-top-left-radius: 0.25em;
            border-bottom-left-radius: 0.25em;
        }

        & li:last-child button.assist-button {
            border-top-right-radius: 0.25em;
            border-bottom-right-radius: 0.25em;
        }
    }

    & button.assist-button {
        margin: 0;
        background-color: #fff;
        width: var(--assist-button-width);
        height: 30px;
        border-radius: 0;
        border-left: none;

        & .fa-youtube {
            color: red;
        }

        & .fa-twitter {
            color: #00acee;
        }

        &.char-code {
            padding: 0;
            font-size: 12px;
            font-weight: bold;
        }

        & + .new1 {
            margin-left: 4px;
        }
    }
}

.edit_form .wiki-edit-assistant {
    margin-top: 0;
    border-top: solid 1px #aaa;
    width: 100%;
}

.wiki-edit-assistant ul.face-image-list {
    --face-image-list-height: 80px;
    --face-image-list-width: 200px;

    position: absolute;
    width: 200px;
    z-index: 999;
    height: 80px;
    top: calc(-1 * (var(--face-image-list-height) - 8px));
    left: calc(-1 * (var(--face-image-list-width) - var(--assist-button-width)));
    border: 1px solid #aaa;
    border-radius: 4px;
    background-color: #FFF;
    list-style: none;
    padding: 4px;
    margin: 0;
    display: flex;
    justify-content: space-around;

    & li {
        padding: 4px;
        cursor: pointer;

        & img {
            vertical-align: middle;
            width: 16px;
        }
    }
}

.edit-assistant-modal {
    & .modal-title {
        color: inherit;
        background-color: inherit;
        border: solid 2px #efefef;
        padding: 0.3em;
        margin: 0 0 0.5em 0;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }

    & .modal-body {
        padding: 8px;
    }

    & .buttons {
        padding: 8px;
        text-align: right;

        & button {
            height: 30px;
        }

        & button.ok-button {
            margin-right: 8px;
        }
    }

    &.color-modal {
        & .color-preview {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80px;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;

            & .preview-character {
                font-size: 16px;
            }
        }

        & .color-picker {
            display: flex;

            & .character-color, & .background-color {
                padding: 0 8px 8px 8px;
            }

            & div[title="#ffffff"] {
                border: solid 1px #d3d3d3;
            }
        }

        & .buttons {
            padding: 8px;
        }
    }

    &.font-size-modal {
        & .modal-body {
            & input[type="number"] {
                width: 80px;
                margin-right: 4px;
            }
        }

        & .font-size-preview {
            background-color: #f5f5f5;
            min-height: 40px;
            padding: 8px;
            text-align: center;
            margin: 8px 0;
        }

        & .buttons {
            padding: 8px 0;
        }
    }

    &.ntbr-modal {
        & div[title="#ffffff"] {
            border: solid 1px #d3d3d3;
        }
    }

    &.table-modal {
        & .modal-body {
            & input[type="number"] {
                width: 80px;
                margin-right: 4px;
            }

            & .spreadsheet-wrapper {
                overflow: auto;
                margin-top: 8px;
                margin-bottom: 8px;
                max-height: 500px;

                & .Spreadsheet table tr td:has(.comment-row-toggle) {
                    position: relative;
                    min-width: 40px;
                }

                & .Spreadsheet table {
                    margin: 0;

                    & td {
                        background-color: inherit;
                    }
                }

                & .Spreadsheet .comment-row-toggle {
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    box-shadow:2px 2px 4px #c7c7c7;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    color: #c7c7c7;

                    &.toggle-on {
                        box-shadow:inset 2px 2px 4px #c7c7c7;
                        color: #3F7F5F;
                    }
                }

                & .Spreadsheet table tr:has(.comment-row-toggle.toggle-on) .Spreadsheet__cell:not(:first-child) .Spreadsheet__data-viewer {
                    color: #3F7F5F;
                    opacity: 0.5;
                }

                & .Spreadsheet table tr:has(.row-modifier-select.select-h) .Spreadsheet__data-viewer,
                & .Spreadsheet table tr:has(.row-modifier-select.select-f) .Spreadsheet__data-viewer {
                    font-weight: bold;
                }

                & .Spreadsheet table tr:has(.row-modifier-select.select-c) .Spreadsheet__data-viewer {
                    opacity: 0.5;
                }
            }
        }

        & .input-description {
            margin-right: 4px;
        }

        & .add-header {
            display: inline-block;
            margin-top: 4px;

            & .input-description {
                margin-left: 4px;
            }
        }
    }

    &.embed-modal {
        & label span {
            width: 60px;
            text-align: right;
            display: inline-block;
            margin-right: 4px;
        }

        & input[type="url"] {
            width: 240px;
        }

        & input[type="number"] {
            width: 80px;
        }
    }

    &.twitter-modal {
        & .twitter-type-select {
            margin-bottom: 8px;

            & label {
                margin-right: 8px;
            }
        }

        & .twitter-params {
            & label {
                display: block;
            }

            & label span {
                width: 60px;
                text-align: right;
                display: inline-block;
                margin-right: 4px;
            }
        }

        & input[type="number"] {
            width: 80px;
        }
    }

    &.link-modal {
        & label span {
            margin-right: 4px;
        }

        & input {
            width: 240px;
        }
    }

    &.plugin-modal {
        --categories-width: 120px;
        --main-area-height: 400px;
        --plugin-description-height: 320px;

        & .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: solid 2px #efefef;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            padding: 4px;

            & .modal-title {
                margin-bottom: 0;
                margin-left: 8px;
                padding: 0;
                border: none;
            }

            & .search-box {
                & input[type="search"] {
                    margin-left: 4px;
                    width: 120px;
                }
            }
        }

        & .modal-body {
            display: flex;
            padding: 0;

            & .categories {
                width: var(--categories-width);
                border-right: solid 2px #efefef;
                padding: 4px;

                & ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    & li {
                        cursor: pointer;
                        text-decoration: underline;
                        padding: 4px;
                    }
                }

            }

            & .main-area {
                width: calc(100% - var(--categories-width));
                height: var(--main-area-height);
                overflow: auto;
                padding: 4px;

                & .plugin-name {
                    font-weight: bold;
                    text-decoration: underline;
                    cursor: pointer;
                }

                & .new1 {
                    margin-left: 4px;
                }

                & .plugin-description {
                    margin-top: 0;
                    margin-bottom: 0;
                    overflow: auto;

                    & .summary, & .args-description, & .note {
                        white-space: pre-wrap;
                    }

                    & .back {
                        display: inline-block;
                        color: #215dc6;
                        cursor: pointer;
                        margin-bottom: 8px;
                    }

                    & .insert-button {
                        margin-left: 1em;
                    }
                }
            }
        }
    }

    &.upload-modal {
        & .upload-zone {
            cursor: pointer;
            height: 50px;
            border: 1px black dashed;
            padding: 8px;

            &.dragging {
                background-color: #f5f5f5;
                border-style: solid;
            }
        }

        & .upload-preview {
            margin-top: 20px;

            & img {
                max-width: 100%;
                max-height: 200px;
                border: 1px solid #ddd;
                padding: 4px;
            }
        }

        & .warning-message {
            border: 1px solid yellow;
            border-radius: .25em;
            background-color: lightyellow;
            color: black;
        }

        & .error-message {
            color: red;
        }

        & .overwrite-mode {
            & .already-existing-warning {
                color: red;
            }
        }
    }
}
