.commentform {
    & input[name="name"] {
        margin-right: 4px;
        height: 30px;
        vertical-align: top;
    }

    & input[type="submit"] {
        height: 30px;
        vertical-align: top;
    }

    & .auto-resize-textarea {
        resize: none;
        vertical-align: bottom;
        margin-right: 4px;
    }

    &.no-edit-assistant {
        margin-bottom: 16px;
    }

    &.has-capacity-warning {
        & input[name="name"],
        & .auto-resize-textarea {
            border: 1px solid #ff0000;
            //background-color: #ffcccc;
        }
    }
}

.comment-main-form + .edit-assistant-area {
    display: none;
    margin-bottom: 16px;

    &.shown {
        display: block;
        animation: fadeIn .8s ease;
    }

    & .comment-capacity-warning {
        color: #ff0000;
    }

    & .controls {
        display: flex;
        align-items: center;

        & .hide-edit-assistant {
            margin-left: 16px;
        }
    }
}

@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
